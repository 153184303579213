import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import PhotoLeft from "../components/photo-left";
import Button from "react-bootstrap/Button";
import Link from "../components/link";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import Navigation from "../components/navigation";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import SVGRing from "../components/SVG/ring";
import SVGVeryThinRing from "../components/SVG/very-thin-ring";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ClassesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      classes: allWpClass {
        nodes {
          title
          id
          uri
          classFields {
            tag
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "classes" }) {
        ourClassesFields {
          ourClassesBanner {
            ourClassesBannerHeading
            ourClassesBannerButton {
              title
              target
              url
            }
          }
          ourClassesLeftImageRightContent {
            ourClassesRightContent
            ourClassesRightButton {
              title
              target
              url
            }
            ourClassesLeftImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
        seoFields {
          metaTitle
          metaDescription
          opengraphTitle
          opengraphDescription
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFields, ourClassesFields },
  } = data;

  const { ourClassesBanner, ourClassesLeftImageRightContent } =
    ourClassesFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Classes",
        item: {
          url: `${siteUrl}/classes`,
          id: `${siteUrl}/classes`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <LocalBusinessSchema />
      <GatsbySeo
        title={seoFields?.metaTitle}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <section
        className="position-relative"
        style={{
          background:
            "transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
          opacity: 1,
        }}
      >
        <Navigation background="none" />
        <SVGVeryThinRing
          style={{ left: "-17%" }}
          className="position-absolute top--70 d-none d-xl-block "
        />
        <SVGRing
          style={{ width: "25rem", height: "25rem" }}
          className="position-absolute top--60 end-10 d-none d-xl-block"
        />
        <Container>
          {" "}
          <Row>
            <Col>
              <h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
                {ourClassesBanner?.ourClassesBannerHeading ||
                  "Our Pilates Classes"}
              </h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="">
        <Container>
          <Row>
            <Col className="text-center">
              <Row className="mt-5  ">
                {data.classes.nodes.map((class1) => (
                  <Col className="mb-10" lg={4}>
                    <BgImage
                      className=" position-relative  w-100"
                      style={{ height: "18rem" }}
                      alt={class1.classFields.altText}
                      image={getImage(
                        class1.classFields.featuredImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      )}
                    >
                      <Row className="justify-content-center ms-0 w-100 position-absolute top-90 start-0  ">
                        <Col
                          as={Link}
                          to={class1.uri}
                          xs={9}
                          className="bg-white ps-4 text-start"
                          style={{
                            boxShadow: "0px 3px 10px #9E9E9E28",
                            opacity: 1,
                          }}
                        >
                          {" "}
                          <p className="mb-0 mt-3" style={{ color: "#C4C4C4" }}>
                            {class1.classFields.tag}
                          </p>
                          <p className="jost-medium text-primary fs-5 mt-2">
                            {class1.title}
                          </p>
                          <p className="contact-link jost-bold">
                            Find out more
                          </p>
                        </Col>
                      </Row>
                    </BgImage>
                  </Col>
                ))}
              </Row>

              {ourClassesBanner?.ourClassesBannerButton &&
                ourClassesBanner?.ourClassesBannerButton.url && (
                  <Button
                    className="px-4 cta-btn mt-4"
                    as={Link}
                    to={ourClassesBanner?.ourClassesBannerButton.url}
                  >
                    {ourClassesBanner?.ourClassesBannerButton.title}
                  </Button>
                )}
            </Col>
          </Row>
        </Container>
      </section>
      {ourClassesLeftImageRightContent &&
        !checkPropertiesForNull(ourClassesLeftImageRightContent, [
          "ourClassesRightContent",
        ]) && (
          <section className="mb-8">
            <PhotoLeft
              img={
                ourClassesLeftImageRightContent?.ourClassesLeftImage.node
                  ?.localFile.childImageSharp.gatsbyImageData
              }
              imgAlt={
                ourClassesLeftImageRightContent?.ourClassesLeftImage.node
                  ?.altText
              }
              text={
                <SafeHtmlParser
                  htmlContent={
                    ourClassesLeftImageRightContent?.ourClassesRightContent
                  }
                />
              }
              bColour="button"
              cta={
                <span className="px-2">
                  {ourClassesLeftImageRightContent?.ourClassesRightButton.title}
                </span>
              }
              ctaUrl={
                ourClassesLeftImageRightContent?.ourClassesRightButton.url
              }
            />
          </section>
        )}
    </Layout>
  );
};

export default ClassesPage;
